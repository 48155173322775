import { NgModule } from '@angular/core';

import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { canActivate, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin: any = () => redirectUnauthorizedTo(['']);

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: (): any => import('../app/modules/auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: 'introduction',
    loadChildren: (): any =>
      import('../app/modules/introduction/introduction.module').then((m) => m.IntroductionModule),
  },
  {
    path: 'tabs',
    loadChildren: (): any => import('../app/modules/tabs/tabs.module').then((m) => m.TabsModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: '',
    redirectTo: '/introduction',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
