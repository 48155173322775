import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { HttpService } from 'src/app/core/services/apis/generic/http.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private httpService: HttpService) {}

  updatePushNotificationToken(notificationToken: string): Observable<void> {
    return this.httpService.post<void>(
      `${environment.urls.api}/profile/updatePushNotificationToken`,
      {
        notificationToken,
      }
    );
  }

  setActiveCompany(companyId: string): Observable<void> {
    return this.httpService.post<void>(`${environment.urls.api}/profile/setActiveCompany`, {
      activeCompany: companyId,
    });
  }

  deleteAccount(email: string): Observable<void> {
    return this.httpService.post<void>(`${environment.urls.api}/profile/deleteAccount`, {
      email,
    });
  }
}
