import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { UserModel } from 'src/app/core/models/user.model';

import { StorageService } from '../utils/storage.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  constructor(
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private angularFireDatabase: AngularFireDatabase,
    private storageService: StorageService
  ) {}

  setFirebaseAuth(): void {
    this.setAuthState();
  }

  signInWithCustomToken(customToken: string): Promise<firebase.default.auth.UserCredential> {
    return this.angularFireAuth.signInWithCustomToken(customToken);
  }

  getAuthIdToken(): Observable<string> {
    return this.angularFireAuth.idToken;
  }

  createUser(user: UserModel): void {
    const reference = this.angularFireDatabase.list<UserModel>('users');

    const { id, email, fullName } = user;

    reference.set(id.toString(), { id, email, fullName });
  }

  updateUser(user: UserModel): void {
    const reference = this.angularFireDatabase.list<UserModel>('users');

    const { id, notificationToken } = user;

    reference.update(id.toString(), { notificationToken });
  }

  getUserById(userId: number): Promise<UserModel> {
    return new Promise((resolve,reject)=>{
      this.angularFireDatabase
      .object<UserModel>(`users/${userId}`).query.once('value').then(res=>{
        res.val();
        resolve(res.val());
      }).catch(()=>reject())
    });
  }

  signOut(): Promise<void> {
    return this.angularFireAuth.signOut();
  }

  private setAuthState(): void {
    this.angularFireAuth.authState.subscribe(async (user: firebase.default.User) => {
      if (!user) {
        const isIntroductionShowed = await this.storageService.get<boolean>('isIntroductionShowed');

        if (!isIntroductionShowed) {
          this.router.navigateByUrl('introduction/introduction');
        } else {
          this.router.navigateByUrl('auth');
        }
      } else {
        this.router.navigateByUrl('tabs/alerts');
      }
    });
  }
}
