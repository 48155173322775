import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { FirebaseAuthService } from 'src/app/core/services/firebase/firebase-auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private firebaseAuthService: FirebaseAuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.firebaseAuthService.getAuthIdToken().pipe(
      take(1),
      switchMap((authIdToken: string) => {
        if (!authIdToken) {
          const authApiKeyReq: HttpRequest<any> = request.clone({
            setHeaders: {
              'Content-Type': 'application/json',
            },
          });

          return next.handle(authApiKeyReq);
        }

        const authReq: HttpRequest<any> = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authIdToken}`,
          },
        });

        return next.handle(authReq);
      })
    );
  }
}
