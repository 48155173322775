import { coerceStringArray } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';

import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';

import { UserDataService } from 'src/app/core/services/data/user-data.service';
import { FirebaseAuthService } from 'src/app/core/services/firebase/firebase-auth.service';
import { ProfileService } from '../apis/profile.service';
import { FirebaseCloudMessagingDataService } from '../data/firebase-cloud-messaging-data.service';
import { AppStateService } from './app-state.service';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class FirebaseCloudMessagingService {
  constructor(
    private userDataService: UserDataService,
    private profileService: ProfileService,
    private firebaseCloudMessagingDataService: FirebaseCloudMessagingDataService,
    private firebaseAuthService: FirebaseAuthService,
    private appStateService: AppStateService,
    private platform: Platform
  ) {}

  async setFirebaseCloudMessaging(): Promise<void> {
    const result = await PushNotifications.requestPermissions();

    PushNotifications.addListener('registration', (token: Token) => {
      const user = this.userDataService.getUser();

      this.userDataService.setUser({
        ...user,
        notificationToken: token.value,
      });

      this.profileService.updatePushNotificationToken(token.value).subscribe();

      this.firebaseAuthService.updateUser({ ...user, notificationToken: token.value });
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {}
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (actionPerformed: ActionPerformed) => {
        const { id, type } = actionPerformed?.notification?.data;

        this.firebaseCloudMessagingDataService.setFirebaseCloudMessaging({
          id,
          type,
        });
      }
    );
    
    if(result.receive === 'granted'){
      PushNotifications.register();
      if(this.platform.is('android')){
        this.createChannel();
      }
   }
   else{
    console.log('is not granted')
   }
  }

  async createChannel(){
    await PushNotifications.createChannel({
      id: 'alert-networks-mobile-fcm-default-channel',
      name: 'alerts',
      sound: 'note.wav'
    })
  }

  removeAllListeners(): void {
    PushNotifications.removeAllListeners();
  }
}
