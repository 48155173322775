import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  appStateChanged$: Observable<boolean>;

  olderDeviceChanged$: Observable<boolean>;

  private appStateSubject: BehaviorSubject<boolean>;

  private olderDeviceSubject: BehaviorSubject<boolean>;

  constructor() {
    this.initialize();
  }

  setAppState(isActive: boolean): void {
    this.appStateSubject.next(isActive);
  }

  setOlderDevice(isOlder: boolean): void {
    this.olderDeviceSubject.next(isOlder);
  }

  private initialize(): void {
    this.appStateSubject = new BehaviorSubject<boolean>(null);
    this.appStateChanged$ = this.appStateSubject.asObservable();

    this.olderDeviceSubject = new BehaviorSubject<boolean>(null);
    this.olderDeviceChanged$ = this.olderDeviceSubject.asObservable();
  }
}
