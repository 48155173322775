import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { FirebaseCloudMessagingModel } from '../../shared/models/firebase-cloud-messaging.model';

@Injectable({
  providedIn: 'root',
})
export class FirebaseCloudMessagingDataService {
  firebaseCloudMessagingChanged$: Observable<FirebaseCloudMessagingModel>;

  private firebaseCloudMessagingSubject: BehaviorSubject<FirebaseCloudMessagingModel>;

  constructor() {
    this.initialize();
  }

  setFirebaseCloudMessaging(firebaseCloudMessaging: FirebaseCloudMessagingModel): void {
    this.firebaseCloudMessagingSubject.next(firebaseCloudMessaging);
  }

  private initialize(): void {
    this.firebaseCloudMessagingSubject = new BehaviorSubject<FirebaseCloudMessagingModel>(null);
    this.firebaseCloudMessagingChanged$ = this.firebaseCloudMessagingSubject.asObservable();
  }
}
