<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" class="app-side-menu">
      <ion-content>
        <ng-container *ngIf="signInChanged$ | async">
          <app-side-menu-profile
            [user]="userChanged$ | async"
            [activeGroup]="activeGroupChanged$ | async"
            (click)="closeMenu()"
          ></app-side-menu-profile>

          <ion-list class="margin-top-50-ios">
            <ng-container *ngIf="userChanged$ | async as user">
              <ion-menu-toggle autoHide="false" *ngIf="user.isRadioRoom">
                <ion-item button lines="none" (click)="goToMenuPage(curatedMenu)" detail="false">
                  <ion-icon
                    slot="start"
                    [name]="curatedMenu.ionicIcon"
                  >
                  </ion-icon>
  
                  <ion-label>
                    {{curatedMenu.title}}
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ng-container>
            <ion-menu-toggle autoHide="false" *ngFor="let m of sideMenuItems; let i = index">
              <ion-item button lines="none" (click)="goToMenuPage(m)" detail="false">
                <ion-icon
                  slot="start"
                  [name]="m.ionicIcon ? m.ionicIcon : ''"
                  [src]="m.customIcon ? m.customIcon : ''"
                >
                </ion-icon>

                <ion-label>
                  {{ m.title }}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <div class="margin-top-60">
            <ion-label class="margin-left-24 color-gray font-size-13">{{
              appVersion
            }}</ion-label>
           <br />
            <ion-label class="margin-left-24 color-gray font-size-13">{{
              copyright
            }}</ion-label>
          </div>
          
        </ng-container>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
