import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { HttpService } from './generic/http.service';

import { UserModel } from 'src/app/core/models/user.model';
import { DeviceInfoModel } from 'src/app/core/models/device-info.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpService: HttpService) {}

  signIn(user: UserModel, deviceInfo: DeviceInfoModel): Observable<UserModel> {
    const { email, password } = user;

    return this.httpService.post<UserModel>(`${environment.urls.api}/auth/signin`, {
      email,
      password,
      deviceInfo,
    });
  }

  forgotPassword(user: UserModel): Observable<string> {
    const { email, phone } = user;

    return this.httpService.post<string>(`${environment.urls.api}/auth/forgotPassword`, {
      email,
      phone,
    });
  }

  signOut(): Observable<void> {
    return this.httpService.post<void>(`${environment.urls.api}/auth/logout`, {});
  }
}
