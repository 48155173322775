import { Injectable } from '@angular/core';

import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  set<T>(key: string, data: Partial<T>): Promise<void> {
    return Preferences.set({
      key,
      value: JSON.stringify(data),
    });
  }

  async get<T>(key: string): Promise<T> {
     const value = await Preferences.get({ key });
    return value?.value && value?.value != 'undefined' ? JSON.parse(value.value) : null
  }

  remove(key: string): Promise<void> {
    return Preferences.remove({ key });
  }

  clear(): void {
    Preferences.clear();
  }
}
