import { Component, Input } from '@angular/core';

import { UserModel } from 'src/app/core/models/user.model';
import { GroupModel } from 'src/app/core/models/group.model';

@Component({
  selector: 'app-side-menu-profile',
  templateUrl: './side-menu-profile.component.html',
  styleUrls: ['./side-menu-profile.component.scss'],
})
export class SideMenuProfileComponent {
  @Input() user: UserModel;

  @Input() activeGroup: GroupModel;

  constructor() {}
}
