export const environment = {
  production: true,
  appVersion: require('../../package.json').version,
  firebaseConfig: {
    apiKey: 'AIzaSyAehRbZRDsGTnwE0qTSnHVVlKMynrX89MY',
    authDomain: 'alertnetworks-net.firebaseapp.com',
    databaseURL: 'https://alertnetworks-net-default-rtdb.firebaseio.com',
    projectId: 'alertnetworks-net',
    storageBucket: 'alertnetworks-net.appspot.com',
    messagingSenderId: '864871295680',
    appId: '1:864871295680:web:871c382bedf4c0a6609b25',
    measurementId: 'G-EVLPMKV4TS',
  },
  urls: {
    api: 'https://api.alertnetworks.net/v1',
    marketData: 'https://alertnetworks.net/wordpress',
    glossary: 'https://alertnetworks.net/wordpress/glossary-of-fire-terminology/',
    marketMapImage: 'https://alertnetworks.net/images/markets/',
    universalMap: 'https://www.google.com/maps/search/?api=1&query=',
    listen: 'https://player.alertnetworks.net/home?job=all',
    sentryDsn: 'https://332bd3074f284a4bbd8241fb6de9dc1a@sentry.alertnetworks.net/10',
    joinOurNetwork: 'https://alertnetworks.net/join',
  },
  fromEmail: 'no-reply@alertnetworks.net',
  toEmail: 'team@alertnetworks.net',
};
