import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { IonMenu, Platform } from '@ionic/angular';

import { ScreenOrientation, OrientationType } from '@capawesome/capacitor-screen-orientation';

import { SplashScreen } from '@capacitor/splash-screen';
import { App } from '@capacitor/app';
import { CapacitorUpdater, latestVersion } from '@capgo/capacitor-updater';

import * as Sentry from '@sentry/browser';

import { SideMenuModel } from './core/models/side-menu.model';
import { UserModel } from 'src/app/core/models/user.model';
import { GroupModel } from 'src/app/core/models/group.model';
import { FilterModel } from './modules/shared/models/filters/filter.model';

import { checkIsNativePlatformFunction } from 'src/app/modules/shared/functions/check-is-native-platform.function';
import { signOutFunction } from './modules/shared/functions/sign-out.function';

import { FirebaseAuthService } from 'src/app/core/services/firebase/firebase-auth.service';
import { UserDataService } from 'src/app/core/services/data/user-data.service';
import { StorageService } from './core/services/utils/storage.service';
import { AuthService } from 'src/app/core/services/apis/auth.service';
import { FirebaseCloudMessagingService } from 'src/app/modules/services/utils/firebase-cloud-messaging.service';
import { AppStateService } from './modules/services/utils/app-state.service';
import { FilterAlertsDataService } from './modules/services/data/filter-alerts-data.service';
import { FilterLeadsDataService } from './modules/services/data/filter-leads-data.service';
import { FilterSoldLeadsDataService } from './modules/services/data/filter-sold-leads-data.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(IonMenu) ionMenu: IonMenu;

  userChanged$: Observable<UserModel>;

  activeGroupChanged$: Observable<GroupModel>;

  signInChanged$: Observable<boolean>;

  sideMenuItems: SideMenuModel[] = [];

  appVersion = 'App version : ' + environment.appVersion;
  copyright = '© Alert Networks ' + (new Date()).getFullYear();
  appEnvironment = environment.production
    ? 'Production Build'
    : 'Development Build';
  curatedMenu = {
    title: 'Curated Leads',
    url: 'tabs/curated-leads',
    ionicIcon: 'flame-outline',
    isSignOut: false,
  }

  constructor(
    private router: Router,
    private platform: Platform,
    private firebaseAuthService: FirebaseAuthService,
    private userDataService: UserDataService,
    private storageService: StorageService,

    private firebaseCloudMessagingService: FirebaseCloudMessagingService,
    private authService: AuthService,
    private appStateService: AppStateService,
    private filterAlertsDataService: FilterAlertsDataService,
    private filterLeadsDataService: FilterLeadsDataService,
    private filterSoldLeadsDataService: FilterSoldLeadsDataService
  ) {}

  ngOnInit(): void {
    this.platform.ready().then(()=>this.initializeApp());
  }

  goToMenuPage(sideMenu: SideMenuModel): void {
    sideMenu.isSignOut ? this.signOut() : this.router.navigateByUrl(sideMenu.url);
  }

  closeMenu(): void {
    this.ionMenu.close();
  }

  private async initializeApp(): Promise<void> {
    this.setSideMenu();

    const [
      user,
      isIntroductionShowed,
      isNotificationPreferencesEnabled,
      isSignedIn,
      email,
      alertsFinalFilter,
      leadsFinalFilter,
      soldLeadsFinalFilter,
    ] = await Promise.all([
      this.storageService.get<UserModel>('user'),
      this.storageService.get<boolean>('isIntroductionShowed'),
      this.storageService.get<boolean>('isNotificationPreferencesEnabled'),
      this.storageService.get<boolean>('isSignedIn'),
      this.storageService.get<string>('email'),
      this.storageService.get<FilterModel>('alertsFinalFilter'),
      this.storageService.get<FilterModel>('leadsFinalFilter'),
      this.storageService.get<FilterModel>('soldLeadsFinalFilter'),
    ]);

    if (user) {
      this.userDataService.setUser(user);
    }

    if (isIntroductionShowed) {
      this.userDataService.setIntroduction(isIntroductionShowed);
    }

    this.userDataService.setNotificationPreferences(isNotificationPreferencesEnabled ?? true);

    if (isSignedIn) {
      this.userDataService.setSignIn(isSignedIn);
    }

    if (email) {
      this.userDataService.setSignInEmail(email); //Remember email when user logs out
    }

    this.firebaseAuthService.setFirebaseAuth();

    if (alertsFinalFilter) {
      this.filterAlertsDataService.setSelectedFinalFilter(alertsFinalFilter);
    }

    if (leadsFinalFilter) {
      this.filterLeadsDataService.setSelectedFinalFilter(leadsFinalFilter);
    }

    if (soldLeadsFinalFilter) {
      this.filterSoldLeadsDataService.setSelectedFinalFilter(soldLeadsFinalFilter);
    }

    if (checkIsNativePlatformFunction()) {
      //run only on native platforms
      await ScreenOrientation.lock({ type: OrientationType.PORTRAIT });
    }

    this.checkDeviceHeight();

    this.setSentryScope();

    this.initializeListener();

    this.userChanged$ = this.userDataService.userChanged$;

    this.signInChanged$ = this.userDataService.signInChanged$;

    this.activeGroupChanged$ = this.userDataService.activeGroupChanged$;

    setTimeout(() => {
      SplashScreen.hide();
    }, 2000);
  }

  private setSideMenu(): void {
    this.sideMenuItems = [
      {
        title: 'Archived',
        url: 'tabs/archived',
        ionicIcon: 'folder-open-outline',
        isSignOut: false,
      },
      {
        title: 'Sold Leads',
        url: 'tabs/sold-leads',
        ionicIcon: 'cash-outline',
        isSignOut: false,
      },
      {
        title: 'Reports',
        url: '/tabs/reports',
        ionicIcon: 'pie-chart-outline',
        isSignOut: false,
      },
      {
        title: 'Claim Numbers',
        url: 'tabs/claims/claim-numbers',
        customIcon: './assets/images/svgs/sideMenu/contact-card.svg',
        isSignOut: false,
      },
      {
        title: 'Academy',
        url: '/tabs/academy/academy',
        ionicIcon: 'school-outline',
        isSignOut: false,
      },
      {
        title: 'Activity',
        url: '/tabs/activity/activity',
        ionicIcon: 'pulse-outline',
        isSignOut: false,
      },
      {
        title: 'Settings',
        url: '/tabs/settings/settings',
        ionicIcon: 'cog-outline',
        isSignOut: false,
      },
      {
        title: 'Support',
        url: '/tabs/support/support',
        ionicIcon: 'help-circle-outline',
        isSignOut: false,
      },
      {
        title: 'Sign Out',
        ionicIcon: 'power-outline',
        isSignOut: true,
      },
    ];
  }

  private async signOut(): Promise<void> {
    signOutFunction(
      this.authService,
      this.firebaseAuthService,
      this.userDataService,
      this.storageService,
      this.firebaseCloudMessagingService
    );

    this.userDataService.setSignInEmail(await this.storageService.get<string>('email')); //Remember email when user logs out
  }

  private checkDeviceHeight(): void {
    const deviceHeight = this.platform.height();

    deviceHeight > 700
      ? this.appStateService.setOlderDevice(false)
      : this.appStateService.setOlderDevice(true);
  }

  private setSentryScope(): void {
    if (!checkIsNativePlatformFunction()) {
      return; // Sentry works only on Native platforms
    }

    Sentry.configureScope((scope) => {
      scope.setTag('firebaseProjectId', environment.firebaseConfig.projectId);
    });
  }

  private initializeListener(): void {
    App.addListener('appStateChange', async ({ isActive }) => {
      console.log('appStateChange listener',isActive);
      
      let version: latestVersion;
      if (isActive) {
        // Ensure download occurs while the app is active, or download may fail
        
      }  
      if (!isActive && version) {
        // Activate the update when the application is sent to background
        await SplashScreen.show();
        try {
          let autoUpdate = await CapacitorUpdater.isAutoUpdateEnabled();            
        } catch (e) {
           // log error
        } finally {
          // Hide the splash screen again if something went wrong
          await SplashScreen.hide();
        }
      }
      // this.appStateService.setAppState(!isActive);
    });
    CapacitorUpdater.addListener('download', (info: any) => {
      console.log('download was fired', info.percent);
    });
  }
}
