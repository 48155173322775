import { AuthService } from 'src/app/core/services/apis/auth.service';
import { UserDataService } from 'src/app/core/services/data/user-data.service';
import { FirebaseAuthService } from 'src/app/core/services/firebase/firebase-auth.service';
import { StorageService } from 'src/app/core/services/utils/storage.service';
import { FirebaseCloudMessagingService } from 'src/app/modules/services/utils/firebase-cloud-messaging.service';

import { checkIsNativePlatformFunction } from './check-is-native-platform.function';

export const signOutFunction = async (
  authService: AuthService,
  firebaseAuthService: FirebaseAuthService,
  userDataService: UserDataService,
  storageService: StorageService,
  firebaseCloudMessagingService: FirebaseCloudMessagingService
): Promise<void> => {
  authService.signOut().subscribe(); // must call here hence no token after next line execution

  await firebaseAuthService.signOut();

  userDataService.setSignIn(null);

  storageService.remove('user');
  storageService.remove('isSignedIn');

  if (!checkIsNativePlatformFunction()) {
    return; //if not a Native app then no need to fire/run below line
  }

  firebaseCloudMessagingService.removeAllListeners(); // Cloud push listeners
};
