<ion-row class="ion-margin-top margin-top-50-ios">
  <ion-col size="12" class="ion-text-center">
    <img [src]="activeGroup?.companyUrl" class="rounded-image image-style ion-margin-start" />
  </ion-col>

  <ion-col size="12" class="ion-text-center">
    <ion-label class="color-white"
      ><strong>{{ activeGroup?.companyName }}</strong></ion-label
    >
  </ion-col>

  <br />

  <ion-col size="12" class="ion-text-center switch-company" *ngIf="user?.groups?.length > 1">
    <ion-label class="color-white remove-outline border-none" routerLink="/tabs/settings/settings"
      >Switch Company</ion-label
    >
  </ion-col>
</ion-row>
