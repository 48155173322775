import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { find, orderBy } from 'lodash';

import { UserModel } from 'src/app/core/models/user.model';
import { GroupModel } from '../../models/group.model';

import { StorageService } from '../utils/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  userChanged$: Observable<UserModel>;

  activeGroupChanged$: Observable<GroupModel>;

  introductionChanged$: Observable<boolean>;

  notificationPreferencesChanged$: Observable<boolean>;

  signInChanged$: Observable<boolean>;

  signInEmailChanged$: Observable<string>;

  private userSubject: BehaviorSubject<UserModel>;

  private activeGroupSubject: BehaviorSubject<GroupModel>;

  private introductionSubject: BehaviorSubject<boolean>;

  private notificationPreferencesSubject: BehaviorSubject<boolean>;

  private signInSubject: BehaviorSubject<boolean>;

  private signInEmailSubject: BehaviorSubject<string>;

  constructor(private storageService: StorageService) {
    this.initialize();
  }

  setUser(user: UserModel): void {
    user = { ...user, groups: orderBy(user.groups, ['companyName'], ['asc']) };

    this.userSubject.next(user);

    this.setActiveGroup(find(user?.groups, (g) => g.isCurrent === 1)); // default company

    this.storageService.set<UserModel>('user', user);
    this.storageService.set<string>('email', user?.email);
  }

  getUser(): UserModel {
    return this.userSubject.getValue();
  }

  setActiveGroup(group: GroupModel): void {
    this.activeGroupSubject.next(group);
  }

  getActiveGroup(): GroupModel {
    return this.activeGroupSubject.getValue();
  }

  setIntroduction(isShowed: boolean): void {
    this.introductionSubject.next(isShowed);

    this.storageService.set<boolean>('isIntroductionShowed', isShowed);
  }

  setNotificationPreferences(isEnabled: boolean): void {
    this.notificationPreferencesSubject.next(isEnabled);

    this.storageService.set<boolean>('isNotificationPreferencesEnabled', isEnabled);
  }

  setSignIn(isSignedIn: boolean): void {
    this.signInSubject.next(isSignedIn);

    this.storageService.set<boolean>('isSignedIn', isSignedIn);
  }

  setSignInEmail(email: string): void {
    this.signInEmailSubject.next(email);
  }

  getSignInEmail(): string {
    return this.signInEmailSubject.getValue();
  }

  private initialize(): void {
    this.userSubject = new BehaviorSubject<UserModel>(null);
    this.userChanged$ = this.userSubject.asObservable();

    this.activeGroupSubject = new BehaviorSubject<GroupModel>(null);
    this.activeGroupChanged$ = this.activeGroupSubject.asObservable();

    this.introductionSubject = new BehaviorSubject<boolean>(null);
    this.introductionChanged$ = this.introductionSubject.asObservable();

    this.notificationPreferencesSubject = new BehaviorSubject<boolean>(true);
    this.notificationPreferencesChanged$ = this.notificationPreferencesSubject.asObservable();

    this.signInSubject = new BehaviorSubject<boolean>(null);
    this.signInChanged$ = this.signInSubject.asObservable();

    this.signInEmailSubject = new BehaviorSubject<string>(null);
    this.signInEmailChanged$ = this.signInEmailSubject.asObservable();
  }
}
