import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';

import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular-ivy';


import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

import { environment } from 'src/environments/environment';

Sentry.init(
  {
    dsn: environment.production ? environment.urls.sentryDsn : '', //run only on prod mode

    // Set your release version, such as "getsentry@1.0.0"
    release: 'alert-networks-mobile@' + environment.appVersion,
    // Set your dist version, such as "1"
    dist: '1',
  },
  // Forward the init method from @sentry/angular
  SentryAngular.init
);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ swipeBackEnabled: false }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    CoreModule,
    AngularFireAuthModule,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    CallNumber,
    OpenNativeSettings,
    InAppBrowser,
    AppRate,
    {
      provide: ErrorHandler,
      // Attach the Sentry ErrorHandler
      useValue: SentryAngular.createErrorHandler(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
